import React from "react"
import { graphql } from "gatsby"

import Bio from "../../components/bio"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Community = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />
      <Bio />
      <h3>Teen's Have Said:</h3>
<p>
<blockquote>"During the questions and answers, many of the students looked around the room and realized that instead of them being the only person with a certain problem, most people experienced the same or similar problems. We all know some people who have considered suicide, had a drug problem, or major family problems. It is amazing when you pass out the index cards and read the different questions to see how worried the majority of students were about a friend or a family member. It really puts a new light on things and puts things in perspective. Thank you."<br/>—Sarah</blockquote>

<blockquote>"Your visit drastically impacted my perspective on life in general. You made me look harder at my previous outlook on life and my Jewish life and my perception of my own life. I have endured my own burden of pain, a family history of depression. All of that did not make me feel too content about myself. Then, after your seminar, I stood back and looked at everything I have accomplished so far, and everything I have to be thankful for and it makes me smile. I will always do the most important thing that anyone could ever ask of me--I will listen."<br/>—Steve</blockquote>

<blockquote>"I especially appreciated the part where you ask the group questions. Where we raised our hands which helped me to get a little bit clearer ,picture of what people in my school are like."
<br/>—Dan</blockquote>

<blockquote>"I would like to thank you for coming to my high school. I enjoyed your visit and liked that you asked for questions from students by using index cards. Some people are too shy when it comes to asking questions dealing with the topics that we were talking about. Index cards were a great idea, and having a speaker like you come to our high school was a great idea because it really made me more aware of what is going on around me and the situations I may be placed in and what I will have to deal with in the future. So, thank you."
<br/>—Robert</blockquote>

<blockquote>"At the end of class when you read some of the questions from my class, I was surprised at how deep some of the students problems had in our class. Some of the questions about drug problems, suicide, family problems really made me realize that many people look fine on the outside and are troubled and have many problems on the inside. After our discussion, I understand that if any of my friends are in trouble, it is important to talk to them and try to get them help. The more you worry and are concerned about your friends, the better a friend you are."
<br/>—Beth</blockquote>

<blockquote>"By you talking to us for just 90 minutes, you set a rule in my heart to make me keep going and helping people. You will never know how much you have helped me help others. Thank you."
<br/>—Judy</blockquote>

<blockquote>"Your words touched my heart today. You definitely sparked something in me and made me realize that there are thousands of kids who are not as lucky as me. I truly did not realize until you came and spoke to me that there were so many kids who had so many different kinds of trouble and hard-core lives."
<br/>—Andrew</blockquote>

<blockquote>"I'm very interested in becoming a Rabbi, and working with young people. It may not be the same kind of work that you do, but I feel strongly as you do that the only way to help other teens is to be friends with teens. You have given me a glimpse as to how an act of charity can change a life forever."
<br/>—Krystal</blockquote>

<blockquote>"I'd like you to thank you for talking to us the other day. I think that teenagers like me feel that they have the worst problems on earth when they feel their parents won't let them do something. You put reality into the face of me and now I know that I have it pretty good."
<br/>—Mike</blockquote>

<blockquote>"I told my parents tonight at dinner some of your stories, and we all shared a conversation. Also, I'm a big Bon Jovi fan. I remember a couple of months ago I was listening to the song 'Run Away' and one of the lines of the song went like this, 'She is a little runaway / Daddy's girl went fast / Now she works the night away' I used to think that it was just a song, and nothing like this exists in real life. But today I know I was wrong. It does exist in the real world. I think that maybe that is the problem. Nobody wants to believe that something so horrible as this is true, and maybe if people just got out there and did something about it just like you did then maybe it wouldn't become as big as it is."
<br/>—Kathy</blockquote>

<blockquote>"I really enjoyed how you acknowledged me and the other students like adults. Most adults don't really think of us kids as being very mature and smart at these things. It was very comforting to me and I'm sure the rest of the class. Thank you."
<br/>—Michelle</blockquote>

<blockquote>"Your stories were compassionate and touching. The one about a woman who had full blown AIDS and asked if she could give her baby a lifetime of mother's love in three months was especially powerful. And it's kind of different for me being Jewish that a rabbi could be talking with such ease and experience about drug use, child molestation, etc., but it was a good difference, and I liked it. I want to thank you for taking the time today to not only open my eyes but others I think as well. Thank you in ways that words cannot express."
<br/>—Denise</blockquote>

<blockquote>"Thank you so much for speaking to us because you really awakened some issues that should be talked about. I liked your honest approach to talk about the issues such as teen violence, violence in general, violence in schools, drugs, gay kids, life on the street, etc. I also enjoyed the part where you took time out to make us all write down issues or questions we had on an index card and then we had discussions about them. I agree with almost every point you made, especially the fact of life getting narrower and narrower as you move on and progress through it and how we can't waste our opportunities in life. From you speaking to our class and our discussion, I learned more and more that we can control our own lives for the most part and that we don't have to get derailed from our lives like other people often do."<br/>—Austin</blockquote>

<blockquote>"It made me appreciate my life and my family. One thing I learned from the session today was that I can always talk to my parents if I need help. Thanks again for taking your time out to talk to us about the issues that we were concerned about."
<br/>—Eli</blockquote>

<blockquote>"The thing that most impressed me about your talk was when you told us go with your strength and follow your dream. I'm going to tell my friend what you taught me because his parents are currently going through a divorce and it is really hard on him. By telling people what to do in tough situations like divorce, maybe it will make him feel a little better."
<br/>—Bill</blockquote>

<blockquote>"I liked how we discussed issues that concern us now. It was interesting to hear the questions that other teens had for you. Some of them really surprised me. You taught me that caring is a very strong power and can save a lot of lives. I think it's important to get people to help and to help people before they start making harmful decisions."
<br/>—Andrea</blockquote>

<blockquote>"I really enjoyed the questions that you asked us. From them we can see our school stacked up against the rest of the country."
<br/>—Linda</blockquote>

<blockquote>"This helped me out by you talking to us about the things you've experienced while you were helping kids out on the street because I have a lot of things going on in my life. When you asked us if anyone knew anyone who did crank or heroin, I was one of the kids that raised their hand. That is when I really started thinking about my friend. He told me once that he is having a lot of problems with his parents and that he turned to drugs to help him cope with the problems he was having at home. I tried several times to get him help, but it never seemed to help because he never wanted to help himself. This helped me a lot to understand what happened to my friend."
<br/>—Nancy</blockquote>

</p>
    </Layout>
  )
}

export default Community

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
